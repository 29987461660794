export enum PermissionEnum {
  USERS_LIST = 'USERS_LIST',
  USERS_CREATE = 'USERS_CREATE',
  USERS_UPDATE = 'USERS_UPDATE',
  USERS_DETAILS = 'USERS_DETAILS',

  CLIENTS_LIST = 'CLIENTS_LIST',
  CLIENTS_CREATE = 'CLIENTS_CREATE',
  CLIENTS_DETAILS = 'CLIENTS_DETAILS',
  CLIENTS_UPDATE = 'CLIENTS_UPDATE',
  CLIENTS_CREATE_ACCOUNT = 'CLIENTS_CREATE_ACCOUNT',
  CLIENTS_UPDATE_ACCOUNT = 'CLIENTS_UPDATE_ACCOUNT',
  CLIENTS_CREATE_DEPARTMENT = 'CLIENTS_CREATE_DEPARTMENT',
  CLIENTS_UPDATE_DEPARTMENT = 'CLIENTS_UPDATE_DEPARTMENT',
  CLIENTS_LIST_ACCOUNTS = 'CLIENTS_LIST_ACCOUNTS',
  CLIENTS_LIST_DEPARTMENTS = 'CLIENTS_LIST_DEPARTMENTS',

  DEVICES_GROUPS_LIST = 'DEVICES_GROUPS_LIST',
  DEVICES_GROUPS_CREATE = 'DEVICES_GROUPS_CREATE',
  DEVICES_GROUPS_UPDATE = 'DEVICES_GROUPS_UPDATE',
  DEVICES_GROUPS_DETAILS = 'DEVICES_GROUPS_DETAILS',
  DEVICES_GROUPS_CREATE_FIELD = 'DEVICES_GROUPS_CREATE_FIELD',
  DEVICES_GROUPS_UPDATE_FIELD = 'DEVICES_GROUPS_UPDATE_FIELD',
  DEVICES_GROUPS_DELETE_FIELD = 'DEVICES_GROUPS_DELETE_FIELD',

  DEVICES_LIST = 'DEVICES_LIST',
  DEVICES_CREATE = 'DEVICES_CREATE',
  DEVICES_DETAILS = 'DEVICES_DETAILS',
  DEVICES_UPDATE = 'DEVICES_UPDATE',

  ORDERS_CREATE = 'ORDERS_CREATE',
  ORDERS_UPDATE = 'ORDERS_UPDATE',
  ORDERS_CHANGE_STATUS = 'ORDERS_CHANGE_STATUS',
  ORDERS_CHANGE_USER = 'ORDERS_CHANGE_USER',
  ORDERS_PRICING_TABLE = 'ORDERS_PRICING_TABLE',
  ORDERS_CREATE_CLIENT_COMMENT = 'ORDERS_CREATE_CLIENT_COMMENT',
  ORDERS_CREATE_COMMENT = 'ORDERS_CREATE_COMMENT',
  ORDERS_CAN_FINISH = 'ORDERS_CAN_FINISH',
  ORDERS_FOR_INVOICE = 'ORDERS_FOR_INVOICE',
  ORDERS_UPDATE_ALL = 'ORDERS_UPDATE_ALL',
  ORDERS_REPORTS = 'ORDERS_REPORTS',
  ORDERS_CAN_DELETE_PROTOCOL = 'ORDERS_CAN_DELETE_PROTOCOL',
  ORDERS_CAN_EDIT_PROTOCOL = 'ORDERS_CAN_EDIT_PROTOCOL',
  ORDERS_COMMENTS_REPORTS = 'ORDERS_COMMENTS_REPORTS',
  ORDERS_UNRESTRICTED_SUMMARY = 'ORDERS_UNRESTRICTED_SUMMARY',

  PERIODIC_ORDERS_LIST = 'PERIODIC_ORDERS_LIST',
  PERIODIC_ORDERS_CREATE = 'PERIODIC_ORDERS_CREATE',
  PERIODIC_ORDERS_DETAILS = 'PERIODIC_ORDERS_DETAILS',
  PERIODIC_ORDERS_REMOVE = 'PERIODIC_ORDERS_REMOVE',
  PERIODIC_ORDERS_UPDATE = 'PERIODIC_ORDERS_UPDATE',

  WORKLOG_REPORTS = 'WORKLOG_REPORTS',

  CALENDAR_ACCESS = 'CALENDAR_ACCESS',
  CALENDAR_ALL_USERS = 'CALENDAR_ALL_USERS',
  CALENDAR_CAN_EDIT = 'CALENDAR_CAN_EDIT',

  DAYS_OFF_EDIT = 'DAYS_OFF_EDIT',
  DAYS_OFF_VIEW_ALL_USERS = 'DAYS_OFF_VIEW_ALL_USERS',
  DAYS_OFF_ACCESS = 'DAYS_OFF_ACCESS',

  VACATIONS_REQUESTS_LIST = 'VACATIONS_REQUESTS_LIST',
  VACATIONS_REQUESTS_CHANGE_STATUS = 'VACATIONS_REQUESTS_CHANGE_STATUS',

  WAREHOUSE_ITEMS_LIST = 'WAREHOUSE_ITEMS_LIST',
  WAREHOUSE_ITEMS_CREATE = 'WAREHOUSE_ITEMS_CREATE',
  WAREHOUSE_ITEMS_UPDATE = 'WAREHOUSE_ITEMS_UPDATE',
  WAREHOUSE_ITEMS_DELETE = 'WAREHOUSE_ITEMS_DELETE',
  WAREHOUSE_ITEMS_UPDATE_STOCK = 'WAREHOUSE_ITEMS_UPDATE_STOCK',

  WAREHOUSE_GROUPS_CREATE = 'WAREHOUSE_GROUPS_CREATE',
  WAREHOUSE_GROUPS_REMOVE = 'WAREHOUSE_GROUPS_REMOVE',
  WAREHOUSE_GROUPS_UPDATE = 'WAREHOUSE_GROUPS_UPDATE',
}
